@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uh4n8r');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uh4n8r#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?uh4n8r') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?uh4n8r') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?uh4n8r##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-credit-card {
  &:before {
    content: $icon-credit-card; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-hourglass {
  &:before {
    content: $icon-hourglass; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-suitcase {
  &:before {
    content: $icon-suitcase; 
  }
}

