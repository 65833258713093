.bg-gradient-dark-blue{
  color: #FFF;
  background: linear-gradient(45deg, #040a18, #082948);
}

.color-primary{
  color: $primary;
}

.container,
.container-fluid {
  padding-right: $container-padding;
  padding-left: $container-padding;
}

.row-lg {
  margin-right: -1rem;
  margin-left: -1rem;

  > [class*="col-"],
  > .col,
  > .slick-list [class*="col-"],
  > .slick-list .col {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.row-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > [class*="col-"],
  > .col,
  > .slick-list [class*="col-"],
  > .slick-list .col {
    padding-right: .5rem;
    padding-left: .5rem;
  }
}

.row-xs {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;

  > [class*="col-"],
  > .col,
  > .slick-list [class*="col-"],
  > .slick-list .col {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
}

.row-no-gutter {
  margin-left: 0;
  margin-right: 0;

  > [class*="col-"],
  > .col,
  > .slick-list [class*="col-"],
  > .slick-list .col {
    padding-right: 0;
    padding-left: 0;
  }
}

.col-auto {
  max-width: 100%;
}
@include media-breakpoint-up(md) {
  .col-md-fifth {
    flex: 0 0 20%;
    max-width: 20%
  }
}
@include media-breakpoint-up(lg) {
  .col-lg-fifth {
    flex: 0 0 20%;
    max-width: 20%
  }
}
@include media-breakpoint-up(xl) {
  .col-xl-fifth {
    flex: 0 0 20%;
    max-width: 20%
  }
}


// ==========================================================================
// Modal
// ==========================================================================
.modal-dialog {
  position: relative;
}
.modal-header {
  display: block;
  padding-bottom: 2rem;
  background: $primary;
  color: $white;
  border-color: transparent;
}
.modal-title {
  padding-right: 1.5rem;
}
.modal-title:not(:last-child) {
  margin-bottom: 2rem;
}
.modal-close {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  width: 3.5rem;
  height: 3.5rem;
  padding: .5rem;
  color: $white;
  font-size: 1.75rem;
  line-height: 1;

  &:hover {
    opacity: .75;
    transform: scale(1.2857142857);
  }
}
.modal-body {
  border-color: transparent;
}
.modal-submit {
  margin-top: 3rem;
}
// .modal-header .close {
//   padding: 1rem;
//   margin: 0 -1rem 0 1rem;
// }
// .modal-title {
//   flex-grow: 1;
//   line-height: 1.2;
// }
// .modal-footer {
//   padding-bottom: 2rem;
// }

@include media-breakpoint-down(sm) {
  .modal-header {
    padding: 1.5rem;
  }
  .modal-body {
    padding: 1.5rem;
  }
}

// ==========================================================================
// forms
// ==========================================================================
// .form-control {
//   min-height: $input-height;
// }

.form-group-lg {
  margin-bottom: 1.75rem;
}

.form-control-white {
  border-color: $white;

  &,
  &:focus {
    background: none;
    color: $white;
  }
  &:focus {
    // background: rgba($black, .1);
    border-color: $white;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($white, .25);
  }

  &::placeholder {
    color: rgba($white, .75);
  }
}

// .form-control {
//   height: auto;
//   background: $white;
// }

textarea.form-control {
  resize: none;
}

// .form-control {
//   background-position: center right .5rem;
//   background-size: 1.25rem;
//   background-repeat: no-repeat;
//   box-shadow: 0 0.125rem 4rem rgba($box-shadow, .1);
// }
// .form-control.is-valid,
// .form-control.is-invalid {
//   padding-right: 2rem;
// }
// .form-control.is-valid {
//   background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill-rule='nonzero' fill='none'%3e%3cpath d='M40 20c0 11.045-8.955 20-20 20S0 31.045 0 20 8.955 0 20 0s20 8.955 20 20z' fill='%23C8E6C9'/%3e%3cpath fill='%2347AD4B' d='M30.602 10.602L17 24.199l-5.602-5.598-2.797 2.797L17 29.801l16.398-16.402z'/%3e%3c/g%3e%3c/svg%3e");
// }
// .form-control.is-invalid {
//   background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath d='M0 20c0 11.045 8.955 20 20 20s20-8.955 20-20S31.045 0 20 0 0 8.955 0 20z' id='a'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cmask id='b' fill='%23fff'%3e%3cuse xlink:href='%23a'/%3e%3c/mask%3e%3cuse fill='%23F8A09A' xlink:href='%23a'/%3e%3cpath d='M20 17.172l5.655-5.656 2.83 2.828L22.829 20l5.655 5.656-2.829 2.828L20 22.828l-5.655 5.656-2.83-2.828L17.172 20l-5.657-5.656 2.83-2.828L20 17.172z' fill='%23AD2D3D' mask='url(%23b)'/%3e%3c/g%3e%3c/svg%3e");
// }

// .form-lg {
//   .form-group {
//     margin-bottom: 1.25rem;
//   }
// }
// .form-submit {
//   margin-top: 3rem;
// }




// ==========================================================================
// custom forms
// ==========================================================================
.custom-control + .custom-control {
  margin-top: .5rem;
}
.custom-control-label::before {
  box-shadow: 0 0.125rem 4rem rgba($box-shadow, .1);
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: transparent;
}

// ==========================================================================
// dropdown
// ==========================================================================

@keyframes dropdownChevron {
  from { transform: translateY(0); }
  to   { transform: translateY(.125rem); }
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-chevron {
  font-size: 0.75rem;
  vertical-align: -0.3ex;

}

// .dropdown-toggle:hover {
//   .dropdown-chevron {
//     animation: dropdownChevron .5s ease-in-out 0s infinite alternate;
//   }
// }
// .dropdown-menu {
//   max-height: calc(100vh - 0.75rem);
//   overflow-y: auto;
// }

// // ==========================================================================
// // input group
// // ==========================================================================
// .input-group {
//   // to fix weird chrome rendering issue
//   background: $input-bg;
// }
// .input-group > .input-group-prepend > .input-group-text,
// .input-group > .input-group-append > .input-group-text,
// .input-group-lg > .input-group-prepend > .input-group-text,
// .input-group-lg > .input-group-append > .input-group-text {
//   // color:
//   font-weight: 700;
//   font-size: 0.875rem;
//   text-transform: uppercase;
//   letter-spacing: $tracking;
// }

// .input-group > .input-group-prepend > .input-group-text,
// .input-group > .input-group-append > .input-group-text {
//   font-size: 0.75rem;
// }


// .input-group-lg > .input-group-prepend > .btn,
// .input-group-lg > .input-group-append > .btn {
//   font-size: $btn-lg-font-size;
// }
// .input-group-prepend + .form-control,
// .input-group-prepend + .form-control + .flatpickr-input {
//   border-left: none;
// }

// .input-group-lg > .input-group-prepend > .input-group-text {
//   padding-right: .25rem;
// }
// .input-group-prepend .input-group-text {
//   border-right: none;
// }


// ==========================================================================
// tables
// ==========================================================================
.table {
  th {
    color: $dark;
  }
  thead th {
    padding-bottom: 1rem;
  }

  .thead-sm {
    th, td {
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }
}

.table:last-child {
  margin-bottom: 0;
}

.table-no-header {
  tr:first-child {
    td,
    th {
      border-top: none;
    }
  }
}

.table-vertically-centered {
  th, td {
    vertical-align: middle;
  }
}

// ==========================================================================
// pagination
// ==========================================================================
.page-link {
  min-width: $font-size-base * $pagination-line-height + 2 * $pagination-padding-y;
  text-align: center;

  transition: background-color 250ms;
}

// ==========================================================================
// breadcrumb
// ==========================================================================
.breadcrumb {
  a {
    color: inherit;
  }
}

.breadcrumb-item.active {
  font-weight: 700;
}


// ==========================================================================
// media
// ==========================================================================
.media-body {
  min-width: 0;
}


// ==========================================================================
// tooltip
// ==========================================================================
.tooltip-inner,
.tooltip .arrow {
  opacity: 0;
  transition: opacity 500ms $ease-out-quint, transform 500ms $ease-out-quint;
  transform: translate3d(0, -20px, 0);
}
.tooltip.show .tooltip-inner{
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.tooltip.show .arrow {
  opacity: 1;
  transform: translate3d(0, -1px, 0);
}
.tooltip-inner {
  padding: 1.5rem;
  box-shadow: 3px 3px 30px rgba(black, .09);
}

// ==========================================================================
// utilities
// ==========================================================================
.text-tertiary {
  color: $tertiary !important;
}
.text-muted-light {
  color: $gray-500 !important;
}

// .text-uppercase {
//   letter-spacing: $tracking !important;
// }

.text-normal {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-light {
  background-color: $light !important;
}
.bg-white {
  background-color: $white !important;
}


.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}


.border-top {
  border-top: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}

.overflow-hidden {
  overflow: hidden !important;
}


.invisible {
  visibility: hidden !important;
}
