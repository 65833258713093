$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

$bluegray-100: #464C58;
$bluegray-200: #363A44;
$bluegray-300: #30353E;
$bluegray-400: #2B2F37;
$bluegray-500: #252830;
$bluegray-600: #202228;
$bluegray-700: #161921;

$violetblue: #8095ff;

$primary: #f5bd52;
$secondary: #082948;
$tertiary: $violetblue;

$primary-light: #f8d085;

$secondary-dark: #040a18;

// $primary-dark: $secondary;
// $primary-darker: #0D1E5B;

$white:    #fff;
$gray-100: #e8e8e8;
$gray-200: #d1d9dd;
$gray-300: #afbac0;
// $gray-400: #C1C1C1;
$gray-400: #919ba2;
$gray-500: #757c84;
$gray-600: #5a6067;
$gray-700: #41444a;
$gray-800: #292b30;
$gray-900: #13131e;
$black: $gray-900;

$near-white: #fcfdfe;

$light: $gray-100;
$dark: $gray-900;

$text-muted: $gray-400;

$box-shadow: #071034;


$text-color     : $gray-900;
$body-color:                $text-color;

$alert-info-bg: #DDE2EF;
$alert-info-color: #01306D;
$alert-info-border: $alert-info-color;

$alert-warning-bg: #F6ECCF;
$alert-warning-color: $body-color;
$alert-warning-border: #CBB121;

$alert-danger-bg: #EFDDDD;
$alert-danger-color: #CC0101;
$alert-danger-border: $alert-danger-color;

$border-color: $gray-200;


$facebook: #405995;
$twitter: #00AAFF;

$xl: 1400px;
$xxl: 1600px;

$grid-gutter-width: 1.5rem;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2)
);

// $container-max-widths: (
//   sm: 100%,
//   md: 100%,
//   lg: 100%,
//   xl: 1140px
// ) !default;

$container-max-widths: (
  xl: 992px
) !default;

// $body-color: $gray-800;

// $grid-columns: 16;

// $zindex-dropdown:                   1000;
// $zindex-sticky:                     1020;
// $zindex-fixed:                      1030;
// $zindex-modal-backdrop:             1040;
// $zindex-modal:                      1050;
// $zindex-popover:                    1060;
// $zindex-tooltip:                    1070;

// $link-color     : $brand-tertiary;

$transition-base:             all .5s $ease-out-quint;
// $transition-fade:             opacity .15s linear !default;
// $transition-collapse:         height .35s ease !default;

// ==========================================================================
// Typography
// ==========================================================================

$font-family-primary   : 'Benton Sans', 'Libre Franklin', sans-serif;
// $font-family-secondary : aktiv-grotesk-extended, sans-serif;
$font-family-base: $font-family-primary;

$font-size-base        : 1rem;
$font-size-lg:         1rem;
// $font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px


$h1-font-size:                2.8125rem;
$h2-font-size:                2.1875rem;
$h3-font-size:                1.3125rem;
$h4-font-size:                1.125rem;
$h5-font-size:                $font-size-base;
$h6-font-size:                .875rem;


$headings-margin-bottom:      1rem;
$headings-font-family:        $font-family-primary;
$headings-font-weight:        700;
$headings-line-height:        1.25;
$headings-color:              inherit;

$display1-size:               5rem !default;
$display2-size:               4.375rem !default;
$display3-size:               3.75rem !default;
$display4-size:               2.125rem !default;

$display1-weight:             800 !default;
$display2-weight:             800 !default;
$display3-weight:             800 !default;
$display4-weight:             800 !default;
$display-line-height:         1 !default;

$line-height-base:            1.5 !default;
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$lead-font-size:              1.5rem !default;
$lead-font-weight: 400;


// ==========================================================================
// Forms
// ==========================================================================

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .4375rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-line-height:       $line-height-base !default;

// $input-btn-focus-width:       .2rem !default;
// $input-btn-focus-color:       rgba($component-active-bg, .25) !default;
// $input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .5625rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-line-height-lg:    $line-height-lg !default;

// $input-btn-border-width:      $border-width !default;

// buttons


$btn-padding-y:               .625rem;
$btn-padding-x:               1.25rem;

$btn-padding-y-lg:            .9375rem;
$btn-padding-x-lg:            2rem;

$btn-padding-y-sm: .375rem;


// $btn-border-width:            2px;

// $btn-border-radius:           999px;
// $btn-border-radius-lg:        999px;
// $btn-border-radius-sm:        999px;

$btn-font-weight: 400;

$input-bg: $white;
$input-group-addon-color:               $text-color;
$input-group-addon-bg:                  $white;
$input-border-width: 2px;

$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-line-height-sm:                  1.5714285714;

$input-padding-y-lg:                    .75rem;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-line-height-lg:                  $input-btn-line-height-lg !default;
//
// $input-group-addon-border-color:        $input-border-color !default;
//
$input-height-inner:                    ($font-size-base * $line-height-base) + ($input-padding-y * 2);
// $input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;

// $input-height-inner-sm:                 ($font-size-sm * $input-btn-line-height-sm) + ($input-padding-y-sm * 2) !default;
// $input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:                 ($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2) !default;
// $input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-border-color: $primary;
$input-focus-border-color: $input-border-color;
$input-placeholder-color: $gray-500;



// ==========================================================================
// Custom controls
// ==========================================================================

$custom-control-gutter:                 .5rem;
// $custom-control-spacer-x:               1rem;

// $custom-control-indicator-size:         1.25rem;
$custom-control-indicator-bg:           $white;

$custom-control-indicator-border-color: $primary;
// $custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=UTF-8,%3csvg width='22' height='14' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.585.125L11 8.624 19.415.125 22 2.741 11 13.875 0 2.741z' fill='#{$primary}' fill-rule='evenodd'/%3e%3c/svg%3e"), "#", "%23") !default;

// $custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=UTF-8,%3csvg width='60' height='36' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M30.038 35.494L30 35.456l-.038.038L0 5.586 5.082.506h.001L30 25.381 54.917.506h.001L60 5.586z' fill='#{$primary}' fill-rule='evenodd'/%3e%3c/svg%3e"), "#", "%23");
// $custom-select-color:               $primary !default;
// $custom-select-bg-size:             0.75rem;

// $custom-control-gutter:                 .5rem !default;
// $custom-control-spacer-x:               1rem !default;

// $custom-control-indicator-size:         1rem !default;
// $custom-control-indicator-bg:           $input-bg !default;

// $custom-control-indicator-bg-size:      50% 50% !default;
// $custom-control-indicator-box-shadow:   $input-box-shadow !default;
// $custom-control-indicator-border-color: $gray-500 !default;
// $custom-control-indicator-border-width: $input-border-width !default;

// $custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
// $custom-control-label-disabled-color:           $gray-600 !default;

// $custom-control-indicator-checked-color:        $component-active-color !default;
// $custom-control-indicator-checked-bg:           $component-active-bg !default;
// $custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default;
// $custom-control-indicator-checked-box-shadow:   none !default;
// $custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

// $custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
// $custom-control-indicator-focus-border-color:   $input-focus-border-color !default;

// $custom-control-indicator-active-color:         $component-active-color !default;
// $custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
// $custom-control-indicator-active-box-shadow:    none !default;
// $custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius:       $border-radius !default;
// $custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23") !default;

// $custom-checkbox-indicator-indeterminate-bg:           $component-active-bg !default;
// $custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color !default;
// $custom-checkbox-indicator-icon-indeterminate:         str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23") !default;
// $custom-checkbox-indicator-indeterminate-box-shadow:   none !default;
// $custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

// $custom-radio-indicator-border-radius:          50% !default;
// $custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;

// $custom-range-track-width:          100% !default;
$custom-range-track-height:         .125rem;
// $custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             $primary;
// $custom-range-track-border-radius:  1rem !default;
// $custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:                   0.625rem;
// $custom-range-thumb-height:                  $custom-range-thumb-width !default;
// $custom-range-thumb-bg:                      $component-active-bg !default;
// $custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           0;
// $custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
// $custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
// $custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               $primary-light;
// $custom-range-thumb-disabled-bg:             $gray-500 !default;

// ==========================================================================
// Tables
// ==========================================================================
$table-accent-bg:  $white;

$table-head-bg:               $gray-300;
$table-head-color:            $dark;

$table-dark-bg:               $bluegray-400 !default;
$table-dark-accent-bg:        $bluegray-300 !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($gray-900, 7.5%) !default;
// $table-dark-color:            $body-bg !default;


// ==========================================================================
// Breadcrumbs
// ==========================================================================

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           1rem !default;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          inherit;
$breadcrumb-active-color:           inherit;
$breadcrumb-divider:                url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.665 1.991L2.188 5.434l3.477 3.442-1.07 1.058-4.555-4.5 4.554-4.5z' fill='%23000' fill-rule='evenodd'/%3e%3c/svg%3e");

$breadcrumb-border-radius:          $border-radius !default;

// ==========================================================================
// Dropdown
// ==========================================================================

$dropdown-border-color: transparent;
$dropdown-link-active-color:        $text-color !default;
$dropdown-link-active-bg:           $gray-200 !default;

// $dropdown-link-color:               $gray-900 !default;
// $dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:            $light !default;


// ==========================================================================
// Modal
// ==========================================================================

// Padding applied to the modal body
$modal-inner-padding:               3rem;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  $white !default;
// $modal-content-border-color:        rgba($black, .2) !default;
// // $modal-content-border-width:        $border-width !default;
// $modal-content-border-radius:       $border-radius-lg !default;
// $modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
// $modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                 $secondary-dark;
$modal-backdrop-opacity:            .8 !default;
// $modal-header-border-color:         $border-color !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            3rem;
$modal-header-padding-x:            3rem;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-xl:                          1140px !default;
// $modal-lg:                          800px !default;
$modal-md:                          585px;
// $modal-sm:                          300px !default;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;



// ==========================================================================
// Tooltips
// ==========================================================================

$tooltip-font-size:                 0.875rem;
$tooltip-max-width:                 20rem;
$tooltip-color:                     $text-color;
$tooltip-bg:                        $white;
// $tooltip-border-radius:             .25rem !default;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 .5rem !default;
$tooltip-padding-x:                 1rem !default;
// $tooltip-margin:                    0 !default;

// $tooltip-arrow-width:               .8rem !default;
// $tooltip-arrow-height:              .4rem !default;
// $tooltip-arrow-color:               $tooltip-bg !default;
