$icomoon-font-path: "../fonts";

$header-height: 3.75rem;

$container-padding: 1rem;

$btn-font-size: .75rem;
$btn-lg-font-size: .75rem;

$box-border-radius: 0.1875rem;
$thumbnail-border-radius: 0.375rem;

$nav-width: 6.25rem;
$nav-mobile-height: 3.75rem;

$tracking: .04em;

$chosen-sprite: none;
$chosen-sprite-retina: none;

// $ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function em($target, $context: $font-size-base) {
   @if $target == 0 { @return 0 }
   @return strip-unit($target) / strip-unit($context) + 0em;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin retina {
	@media
	(-webkit-min-device-pixel-ratio: 2),
	(min-resolution: 192dpi) {
		@content;
	}
}

// @mixin dots($color: $gray-500) {
//   background-image: str-replace(url("data:image/svg+xml;charset=UTF-8,%3csvg width='56' height='56' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='#{$color}' cx='28' cy='28' r='16' fill-rule='evenodd'/%3e%3c/svg%3e"), "#", "%23");
//   background-position: 50% 50%;
//   background-size: contain;
//   background-repeat: space;
// }

@mixin dots($color: $violetblue) {
  background-image: str-replace(url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='#{$color}' cx='10' cy='10' r='2' fill-rule='evenodd'/%3e%3c/svg%3e"), "#", "%23");
  background-position: 50% 50%;
  background-size: .75rem;
  background-repeat: space;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

%menu {
  margin: 0;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: $tracking;
}

%box-shadow {
  box-shadow: 0 0 6.25rem rgba($box-shadow, .36);
}
