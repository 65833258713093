body {
	text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

// h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-family-primary;
  font-weight: 700;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: -.06em !important;
}

// $h1-font-size:                2.5rem;;
// $h2-font-size:                1.625rem;
// $h3-font-size:                1.3125rem;
// $h4-font-size:                1.125rem;
// $h5-font-size:                $font-size-base;
// $h6-font-size:                .875rem;

// $display1-size:               5rem !default;
// $display2-size:               4.375rem !default;
// $display3-size:               3.75rem !default;
// $display4-size:               2.125rem !default;

@media (max-width: $xl) {
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.375rem;
  }
  .display-3 {
    font-size: 2.75rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }
}
@include media-breakpoint-down(lg) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .display-1 {
    font-size: 3rem;
  }
  .display-2 {
    font-size: 3.375rem;
  }
  .display-3 {
    font-size: 2.75rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }
}
@include media-breakpoint-down(sm) {
  .display-1 {
    font-size: 2rem;
  }
  .display-2 {
    font-size: 1.5rem;
  }
  .display-3 {
    font-size: 1.25rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }

  h1, .h1 {
    font-size: 1.5rem;
  }
  h2, .h2 {
    font-size: 1.3125rem;
  }
  h3, .h3 {
    font-size: 1.25rem;
  }
  // h4, .h4 {
  //   font-size: 1.25rem;
  // }
  // h5, .h5 {
  //   font-size: 1rem;
  // }
  // h6, .h6 {
  //   font-size: 0.875rem;
  // }
}


.Heading {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: $tracking;
}

a {
	transition: color 250ms;
}

button,
input {
  overflow: visible;
  outline: none;
}
